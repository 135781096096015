.red {
  color: #f43f4f;
}

.orange {
  color: #d45113;
}

.blue  {
  color: #265fe4;
}

.primary {
  color: #83d0f5;
}
