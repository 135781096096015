.smallForm {
}

.smallForm .label {
  font-size: 12px;
  margin-bottom: 3px;
}

.smallForm .os-input-text {
  padding: 2px 10px;
}

.smallForm .os-input-container {
  margin-bottom: 5px;
}
