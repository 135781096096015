.item,
.item:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.item {
  font-size: 2px;
  position: relative;
  text-indent: -9999em;
  border-style: solid;
  border-width: 2px;
  border-left-color: transparent !important;
  transform: translateZ(0);
  animation: newOrionLoading 0.5s infinite linear;
}

@-webkit-keyframes newOrionLoading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes newOrionLoading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
