.container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 30px;
  padding: 20px 0;
  font-weight: bold;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 2;
}

.title i {
  font-size: 20px;
  font-weight: lighter;
}

.message {
  width: 100%;
  padding: 20px;
  background: #f9f9f9;
  color: #555;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.message pre {
  max-height: 200px;
  width: 100%;
  max-width: 1200px;
  overflow: scroll;
  color: #888;
}

.retry {
  font-size: 15px;
  background: #f9f9f9;
  color: #888;
  font-style: italic;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sentryId {
  font-size: 10pt;
  color: #808080;
  margin-top: 16px;
}