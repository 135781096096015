body {
  margin: 0;
  padding: 0;
  background: #fff;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
}

* {
  box-sizing: border-box;
}

.product-card + .product-card {
  content-visibility: auto; /* [1] */
  contain-intrinsic-size: 360px 160px;  /* [2] */
 }