.os_container {
  margin: 0 auto;
  padding: 0 20px;
  padding: 0 calc(20px + env(safe-area-inset-left));
  @media screen and (min-width: 1200px) {
    padding: 0 calc(env(safe-area-inset-left));
  }
}

.shadow {
  box-shadow: 0 4px 35px rgba(50, 50, 93, 0.1), 0 8px 15px rgba(0, 0, 0, 0.07);
}

.smallShadow {
  box-shadow: 0 2px 4px rgba(50, 50, 93, 0.1);
}

.buttonShadow {
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(50, 50, 93, 0.1);
  transition: all 0.15s ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.buttonShadow:hover {
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.buttonShadow:active {
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(50, 50, 93, 0.1);
}

@media (hover: none) {
  .buttonShadow:hover {
    transform: none;
    box-shadow: none;
  }
  .buttonShadow:target {
    transform: translateY(4px);
    box-shadow: 0 2px 2px rgba(50, 50, 93, 0.1);
  }
}

.buttonShadowHover {
  cursor: pointer;
  transition: all 0.15s ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.buttonShadowHover:hover {
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.buttonShadowHover:active {
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(50, 50, 93, 0.1);
}

@media (hover: none) {
  .buttonShadowHover:hover {
    transform: none;
    box-shadow: none;
  }
  .buttonShadowHover:target {
    transform: translateY(4px);
    box-shadow: 0 2px 2px rgba(50, 50, 93, 0.1);
  }
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.content {
  box-shadow: 0 2px 4px rgba(50, 50, 93, 0.1);
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  color: #111;
}

.no-padding {
  padding: 0;
}

.divider {
  background: #eee;
  height: 1px;
  width: 100%;
  margin: 40px 0;
}

.loadingGlow {
  opacity: 0.4;
  background-color: #eee;
  border-radius: 4px;
  animation: loadingGlow 0.9s infinite;
}

@keyframes loadingGlow {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.5;
  }
}

/* Fix para modo oscuro */

.os_modal_content {
  color: #111;
}
