.container {
  min-height: 100vh;
  padding: 40px;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.image {
  max-height: 400px;
}

.title {
  color: #999;
  margin-bottom: 30px;
}

.logo img{
  margin: 0 auto;
}

.logo img {
  height: 20px;
  opacity: 0.3;
}
