.container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loading {
  font-size: 5px;
  border-color: #d5d8df;
}