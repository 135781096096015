.container {
  padding: 20px;
}

.label {
  font-weight: bold;
}

.description {
  composes: description from global;
}

.icon {
  position: relative;
  left: -3px;
}
