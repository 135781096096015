.card {
  border: 1px solid #eaedf3;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  background: #fff;
}

.cardButton {
  border: 1px solid #eaedf3;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  background: #fff;

  cursor: pointer;
  transition: all 0.15s ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.cardButton:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
}

.cardButton:active {
  transform: translateY(1px);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.02);
}
